import React, { useEffect } from "react";

const isAndroid = navigator.userAgent.indexOf("Android") !== -1;
const webAppUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8081"
    : "https://diviz.expo.app";

function App() {
  useEffect(() => {
    if (window.innerWidth > 768) {
      if (window.location.href.includes("token_hash")) {
        const parsedPathname = window.location.pathname.split("/");
        const token =
          parsedPathname[
            parsedPathname.findIndex((el) => el === "token_hash") + 1
          ];
        const type =
          parsedPathname[parsedPathname.findIndex((el) => el === "type") + 1];
        const redirectUrl = `${webAppUrl}/?token_hash=${token}&type=${type}`;
        window.location.replace(redirectUrl);
      }
    }
  }, []);

  const onPressGoogle = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=fr.marhold.splitit&hl=fr"
    );
  };
  const onPressApple = () => {
    window.open(
      "https://apps.apple.com/app/splitit-comptes-entre-ami-e-s/id6480421720"
    );
  };

  return (
    <div className="app">
      <h1 className="h1">DiviZ</h1>
      <div className="main-row">
        <img
          onClick={onPressGoogle}
          src="/playstore.svg"
          alt="playstore_logo"
        />
        {!isAndroid && (
          <img onClick={onPressApple} src="/appstore.svg" alt="appstore_logo" />
        )}
      </div>
      <a href={webAppUrl} className="button">
        Accéder à DiviZ sur navigateur
      </a>
    </div>
  );
}

export default App;
